@font-face {
    font-family: "icons";
    src: url("./icons.eot?d4f2ff8a42357bf4ee13b54468e65c42#iefix") format("embedded-opentype"),
url("./icons.woff2?d4f2ff8a42357bf4ee13b54468e65c42") format("woff2"),
url("./icons.woff?d4f2ff8a42357bf4ee13b54468e65c42") format("woff");
}

*[class^="icon-"]:before, *[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cake:before {
    content: "\f101";
}
.icon-chevron-down:before {
    content: "\f102";
}
.icon-chevron-left:before {
    content: "\f103";
}
.icon-dog:before {
    content: "\f104";
}
.icon-grid:before {
    content: "\f105";
}
.icon-logout:before {
    content: "\f106";
}
.icon-minus:before {
    content: "\f107";
}
.icon-pet:before {
    content: "\f108";
}
.icon-plus:before {
    content: "\f109";
}
.icon-send:before {
    content: "\f10a";
}
.icon-weight:before {
    content: "\f10b";
}
